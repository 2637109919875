import React from "react";
import me from '../assets/images/IMG_90999.png'

const About = () => {
    
    // const info = [
    //     {text:'Years experience', count:'04'},
    //     {text:'Completed Projects', count:'24'},
    //     {text:'Companies Work', count:'06'},
    // ]
    
    return (
        <section id="about" className="py-10 text-gray-600">
            <div className="text-center mt-8">
                <h3 className="text-4xl font-semibold">
                    About <span className="text-light-green">Me</span>
                </h3>
                <p className="text-gray-400 my-3 text-lg">Who is she?</p>
                <div className="flex md:flex-row flex-col-reverse items-center md:gap-6 gap-12 px-10 max-w-6xl mx-auto">
                    <div className="p-2">
                        <div className="text-gray-600 my-3">
                            <p className="text-justify leading-7 w-11/12 mx-auto">I'm a front-end web developer based in Los Angeles, California. I have a passion for both design and development. I love books, tea, and making cool things.</p>
                            <hr className="my-2"/>
                            <p className="text-justify leading-7 w-11/12 mx-auto">Currently Learning: TypeScript</p>
                            <div className="flex mt-10 items-center gap-7">
                                {/* {
                                    info.map(content => (
                                        <div key={content.text}>
                                            <h3 className="md:text-4xl text-2xl font-semibold tex-white">{content.count}<span className="text-light-green">+</span>{' '}</h3>
                                            <span className="md:text-base text-xs">{content.text}</span>
                                        </div>
                                    ))
                                } */}
                                <br /><br />
                                <a href='./src/assets/Gabriela_Flores_Resume.pdf' download>
                                <button className="btn-primary">
                                    Download Resume
                                </button>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="flex-1 md:mt-0 mt-6 flex justify-center items-center">
                        <div className="lg:w-96 h-full relative sm:w-10/12 w-11/12 max-w-sm aboutImg">
                            <img src={me} alt='' className="w-full object-cover bg-light-green rounded-xl"/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
};

export default About;
