import React from "react";

const Skills = () => {

    const skills = [
        {
            logo:'logo-html5', level:'HTML', count: 85
        },
        {
            logo:'logo-css3', level:'CSS', count: 70
        },
        {
            logo:'logo-nodejs', level:'JavaScript', count: 50
        },
        {
            logo:'logo-react', level:'React', count: 39
        }
    ]

    return (
        <section id="skills" className="py-10 bg-gray-100 relative">
            <div className="mt-8 text-gray-600 text-center">
                <h3 className="text-4xl font-semibold">Skills</h3>
                {/* <p className="text-gray-400 mt-3 text-lg">My knowledge</p> */}
                <div className="flex items-center justify-center mt-12 gap-10 flex-wrap">
                    {/* <div className="border-2 border-cyan-600 relative min-w-[10rem] max-w-[16rem] bg-gray-900 p-10 rounded-xl">
                        <div style={{
                            background:`conic-gradient(rgb(8,145,170) 86%,#ddd 86%)`
                        }} className="w-32 h-32 flex items-center justify-center rounded-full">
                            <div className="text-6xl w-28 h-28 bg-gray-900 rounded-full flex items-center justify-center">
                                <ion-icon name="logo-html5"></ion-icon>
                            </div>
                        </div>
                        <p className="text-xl mt-3">Advanced</p>
                    </div> */}
                    {skills?.map((skill, i) => (
                        <div key={i} className="border-2 group border-coral relative min-w-[10rem] max-w-[16rem] bg-white p-10 rounded-xl">
                        <div style={{
                            background:`conic-gradient(rgb(145,172,121) ${skill.count}%,#ddd ${skill.count}%)`
                        }} className="w-32 h-32 flex items-center justify-center rounded-full">
                            <div className="text-6xl w-28 h-28 bg-white rounded-full flex items-center justify-center group-hover:text-coral">
                                <ion-icon name={skill.logo}></ion-icon>
                            </div>
                        </div>
                        <p className="text-xl mt-3">{skill.level}</p>
                    </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Skills;
