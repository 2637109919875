import React, { useState } from "react";

const Contact = () => {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        setName('');
        setEmail('');
        setMessage('');
    }


    return (
        <section id='contact' className="py-10 px-3 text-gray-600">
            <div className="text-center mt-8">
                <h3 className="text-4xl font-semibold">
                    Contact <span className="text-light-green">Me</span>
                </h3>
                <p className="text-gray-400 mt-3 text-lg">Currently looking for new opportunites. My inbox is always open!</p>

                <div className="mt-16 flex md:flex-row flex-col gap-6 max-w-5xl bg-gray-100 p-6 md:p-6 rounded-lg mx-auto">

                    <form className="flex flex-col flex-1 gap-5" action="https://formsubmit.co/gabriela@gflo.me" method="POST">
                        <input onChange={(e) => setName(e.target.value)} name='name' type="text" value={name} placeholder="Name" required/>
                        <input onChange={(e) => setEmail(e.target.value)} name='email' type="email" value={email} placeholder="Email" required/>
                        <textarea onChange={(e) => setMessage(e.target.value)} name='message' value={message} placeholder="Message" rows={10} required></textarea>
                        <input type="hidden" name="_next" value="https://gflo.me"></input>
                        <button type='submit' className="btn-primary w-fit" onSubmit={handleSubmit}>Send</button>
                    </form>

                    <div className="flex flex-col gap-7 items-center">
                        <div className="flex flex-wrap flex-col gap-4 w-fit items-center">
                            <div className="min-w-[3.5rem] text-3xl min-h-[3.5rem] flex items-center justify-center text-white bg-light-green rounded-full">
                                <ion-icon name="mail"></ion-icon>
                            </div>
                            <p className="text-lg">gabriela@gflo.me</p>
                            <div className="min-w-[3.5rem] text-3xl min-h-[3.5rem] flex items-center justify-center text-white bg-light-green rounded-full">
                                <ion-icon name="location"></ion-icon>
                            </div>
                            <p className="text-base">Los Angeles, CA</p>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    );
};

export default Contact;
