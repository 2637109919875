import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import paired1 from "../assets/images/paired1.png";
import paired2 from "../assets/images/paired2.png";
import paired3 from "../assets/images/paired3.png";
import paired4 from "../assets/images/paired4.png";
import pete1 from "../assets/images/pete1.png";
import pete2 from "../assets/images/pete2.png";
import pete3 from "../assets/images/pete3.png";
import pete4 from "../assets/images/pete4.png";
import bookmark1 from "../assets/images/bookmark1.png";
import bookmark2 from "../assets/images/bookmark2.png";
import bookmark3 from "../assets/images/bookmark2.png";
import 'swiper/css';
import 'swiper/css/pagination';
import { Autoplay } from 'swiper';

const Project = () => {

    // const projects = [
    //     { img: [paired1, paired2, paired3, paired4], name: "Paired Up", github_link: 'https://github.com/floresgabriela/paired-up', desc: 'E-commerce shoe store built with MERN and Stripe API' },
    //     { img: [pete1, pete2, pete3, pete4], name: "Pete's Algos", github_link: 'https://github.com/floresgabriela/petesalgos', desc: 'Platform for developers to post, track, and manage algorithms built with Java and MySQL' },
    //     { img: [bookmark1, bookmark2, bookmark3], name: 'Bookmark', github_link: 'https://github.com/floresgabriela/bookmark', desc: 'An application to organize books into lists built with Python, Flask, MySQL' },
    // ];

    const paired = [paired1, paired2, paired3, paired4]
    const pete = [pete1, pete2, pete3, pete4]
    const bookmark = [bookmark1, bookmark2, bookmark3]

    return (
        <section id="projects" className="py-20 text-gray-600">
            <div className="text-center">
                <h3 className="text-4xl font-semibold">
                    My <span className="text-light-green">Projects</span>
                </h3>
            </div>
            <br />
            <div className="text-center p-10 lg:flex">
                <Swiper
                    className="w-full lg:w-1/4 h-full bg-gray-100 rounded-xl my-5 lg:my-0"
                    loop={true}
                    autoplay={{
                        delay: 3000,
                    }}
                    modules={[Autoplay]}
                >
                    {
                        paired.map((image, i) => {
                            return (
                                <SwiperSlide>
                                    <img key={i} src={image} alt='screenshot of Paired Up project' className="rounded-lg w-11/12 mx-auto my-4"/>
                                </SwiperSlide>
                            )
                        })
                    }

                    <h3 className="text-xl my-3 text-coral">Paired Up</h3>
                    <p className="p-2">E-commerce shoe store built with MERN and Stripe API</p>
                    <hr className="w-5/6 mx-auto m-2"/>
                    <a href='https://github.com/floresgabriela/paired-up' target="_blank" rel="noreferrer" className="mb-4">— <span className="text-light-green hover:text-coral">GitHub</span> —</a>
                </Swiper>
                <Swiper
                    className="w-full lg:w-1/4 h-full bg-gray-100 rounded-xl my-5 lg:my-0"
                    loop={true}
                    autoplay={{
                        delay: 3000,
                    }}
                    modules={[Autoplay]}
                >
                    {
                        pete.map((image, j) => {
                            return (
                                <SwiperSlide>
                                    <img key={j} src={image} alt="screenshot of Pete's Algos project" className="rounded-lg w-11/12 mx-auto my-4"/>
                                </SwiperSlide>
                            )
                        })
                    }

                    <h3 className="text-xl my-3 text-coral">Pete's Algos</h3>
                    <p className="p-2">Platform for developers to post, track, and manage algorithms built with Java and MySQL</p>
                    <hr className="w-5/6 mx-auto m-2"/>
                    <a href='https://github.com/floresgabriela/petesalgos' target="_blank" rel="noreferrer" className="mb-4">— <span className="text-light-green hover:text-coral">GitHub</span> —</a>
                </Swiper>
                <Swiper
                    className="w-full lg:w-1/4 h-full bg-gray-100 rounded-xl my-5 lg:my-0"
                    loop={true}
                    autoplay={{
                        delay: 3000,
                    }}
                    modules={[Autoplay]}
                >
                    {
                        bookmark.map((image, k) => {
                            return (
                                <SwiperSlide>
                                    <img key={k} src={image} alt='screenshot of Bookmark project' className="rounded-lg w-11/12 mx-auto my-4"/>
                                </SwiperSlide>
                            )
                        })
                    }

                    <h3 className="text-xl my-3 text-coral">Bookmark</h3>
                    <p className="p-2">An application to organize books into lists built with Python, Flask, MySQL</p>
                    <hr className="w-5/6 mx-auto m-2"/>
                    <a href='https://github.com/floresgabriela/bookmark' target="_blank" rel="noreferrer" className="mb-4">— <span className="text-light-green hover:text-coral">GitHub</span> —</a>
                </Swiper>
            </div>
            {/* <div className="text-center p-10 lg:flex">
                {
                    projects.map((project, i) => {
                        return (
                            <Swiper
                            key={i}
                            className="w-2/3 lg:w-1/4 h-full bg-gray-100 rounded-xl my-5 lg:my-0"
                            loop={true} 
                            autoplay={{
                                delay:1000,
                            }}
                            modules={[Pagination, Autoplay]}
                            >
                                    {
                                        projects.map((project) => project.img.map((image, j) => {
                                            return (
                                                <SwiperSlide key={i}>
                                                    <img key={j} src={image} alt='' className="rounded-lg w-11/12 mx-auto my-4"/>
                                                </SwiperSlide>
                                            )
                                        }))
                                    }
                                <h3 className="text-xl my-4 text-coral">{project.name}</h3>
                                <p className="p-2">{project.desc}</p>
                                <hr className="w-5/6 mx-auto m-2"/>
                                <a href={project.github_link} target="_blank" rel="noreferrer" className="mb-4">— <span className="text-light-green hover:text-coral">GitHub</span> —</a>
                            </Swiper>
                        )
                    })
                }
            </div> */}
            {/* <div className="flex max-w-6xl px-5 mx-auto items-center relative">
                <div className="lg:w-2/3 w-full">
                    <Swiper 
                    spaceBetween={20} 
                    breakpoints={{
                        768: {slidesPerView: 3}
                    }} 
                    loop={true} 
                    autoplay={{
                        delay:3000,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Pagination, Autoplay]}
                >
                    {
                        projects.map((project_info, i) => (
                            <SwiperSlide key={i}>
                            <div className="h-fit w-full p-4 bg-gray-100 rounded-xl">
                                <img src={project_info.img} alt='' className="rounded-lg"/>
                                <h3 className="text-xl my-4 text-coral">{project_info.name}</h3>
                                <div className="flex gap-3">
                                    <a href={project_info.github_link} target="_blank" className="text-light-green bg-white px-2 py-1 inline-block">
                                        Github
                                    </a>
                                </div>
                            </div>
                        </SwiperSlide>
                        ))
                    }
                        <SwiperSlide>
                            <div className="h-fit w-full p-4 bg-slate-700 rounded-xl">
                                <img src={project1} alt='' className="rounded-lg"/>
                                <h3 className="text-xl my-4">Project Title</h3>
                                <div className="flex gap-3">
                                    <a href="" className="text-cyan-600 bg-gray-800 px-2 py-1 inline-block">
                                        Github
                                    </a>
                                    <a href="" className="text-cyan-600 bg-gray-800 px-2 py-1 inline-block">
                                        Live Demo
                                    </a>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="h-fit w-full p-4 bg-slate-700 rounded-xl">
                                <img src={project1} alt='' className="rounded-lg"/>
                                <h3 className="text-xl my-4">Project Title</h3>
                                <div className="flex gap-3">
                                    <a href="" className="text-cyan-600 bg-gray-800 px-2 py-1 inline-block">
                                        Github
                                    </a>
                                    <a href="" className="text-cyan-600 bg-gray-800 px-2 py-1 inline-block">
                                        Live Demo
                                    </a>
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div> */}
        </section>
    )
};

export default Project;
