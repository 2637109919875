import React from "react";
import hero from '../assets/images/heroimage1.png';

const Hero = () => {

    const social_media = [
        {logo:'logo-linkedin', link:'https://www.linkedin.com/in/g-flo/'},
        {logo:'logo-github', link:'https://github.com/floresgabriela'},
    ]

    return (
        <section id='home' className='min-h-screen flex py-10 md:flex-row flex-col items-center'>
            <div className="flex-1 flex items-center justify-center h-full">
                <img src={hero} alt='me' className="md:w-11/12 h-full object-cover pointer-events-none"/>
            </div>
            <div className="flex-1">
                <div className="md:text-left text-center">
                    <h1 className="md:text-5xl text-2xl md:leading-normal leading-10 text-gray-600 font-bold">
                        <span className="text-light-green md:text-6xl text-5xl">
                            Hello!
                            <br />
                        </span>
                        My name is <span>Gabriela Flores.</span>
                    </h1>
                    <h4 className="md:text-2xl text-lg md:leading-normal leading-5 mt-4 font-bold text-gray-400">Front-end Developer</h4>
                    <a href="#contact">
                        <button className="btn-primary mt-8">Contact Me</button>
                    </a>
                    <div className="mt-8 text-3xl flex md:justify-start justify-center gap-5">
                        {
                            social_media?.map(icon=>(
                                <div key={icon.logo} className="text-gray-400 hover:text-black cursor-pointer">
                                    <a href={icon.link} target="_blank" rel='noreferrer'>
                                        <ion-icon name={icon.logo}></ion-icon>
                                    </a>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </section> 
        );
};

export default Hero;
