import React from "react";

const Footer = () => {
    return (
        <div className="bg-gray-100 text-sm p-4 text-center text-coral">
            Made with ♡ by Gabriela Flores
        </div>
    )
};

export default Footer;
